import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';

const Logo = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      {/*made logos go back to landing page, gave onclick div */}
      <h1 className="m-0">
      <div onClick={() => window.location.replace("#landing")}>
        <Link to="/">
          <Image
            src={require('./../../../assets/images/jsonquest-logowfullname.png')}
            alt="Open"
            width={200}
            height={32} />
        </Link>
        </div>
      </h1>
    </div>
  );
}

export default Logo;